<!--客服管理-->
<template>
    <div class="mainBox">
        <div class=" serviceBox table">
            <el-form ref="formDetailAuth" :model="formData" label-width="100px" label-position="top" size="small" class="alertForm service_form">
                <el-form-item label="文章内容:" class="service_label">
                    <editorBar :editorMain = "formData.content" @change="editorChange"></editorBar>
                </el-form-item>
            </el-form>
            
            <el-row type="flex" justify="center">
                <el-button type="primary" size="small" style="margin:30px 0 0 0px;display: block;width:300px" @click="submit">确定</el-button>
            </el-row>        
            
        </div>
    </div>
</template>
<script>
    import editorBar from '@/components/editorBar';
    import {serviceReadApi,serviceSaveApi}  from '@/api/config.js';
    export default {
        data() {
            return {
                formData:{
                    content:''
                }
            }
        },
        components:{
            editorBar
        },
        mounted() {
            this.readDetail();
        },
        methods: {
            //客服读取详情
            readDetail:function(){
                serviceReadApi().then(response=>{
                    this.formData.content = response.content;
                })
            },
            //接收富文本值
            editorChange: function(val){
                this.formData.content = val;
            },
            //客服提交
            submit:function(){
                serviceSaveApi({
                    content:this.formData.content
                }).then(()=>{
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                    this.readDetail();
                })
            }
        },
    }
</script>